import StyleableDiv from "@/components/help-center/custom-tags/StyleableDiv.vue";

export const styleableDiv = {
  render: StyleableDiv,
  attributes: {
    styles: {
      type: String,
    },
  },
};
